// src/pages/form-success.js

import React from "react"

const FormSuccess = () => {
  return (
      <div className="flex w-screen h-screen bg-white items-center pt-48 flex-col gap-4">
        <img className="w-64" src="https://cdn.dribbble.com/users/2185205/screenshots/7886140/media/90211520c82920dcaf6aea7604aeb029.gif"></img>
        <h1 className="text-2xl">Thank you</h1>
        <p className="text-lg text-center opacity-60">Your interest has been received.<br/> We will be in touch and contact you soon!</p>
      </div>
  )
}

export default FormSuccess
